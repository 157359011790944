(function(){
  let app = angular.module('app', [
    "ngMaterial",
    "infinite-scroll",
    "accessibility",
    "share",
    "search",
    "ch.filters",
    "dndLists",
    'afkl.lazyImage',
    'vcRecaptcha'
  ]);
  let spinner = null;
  let oldclass = "";
  let functions = {};
  let toggle_sponsor_state = 1;

  app.run(["$filter", "$rootScope", "$http", function($filter, $rootScope, $http)
  {
    $rootScope.topic = {topic_id: null, topic_is_tag: false};
    $rootScope.distances = [1,5,10,25,100,250,500,"Any"];
    $rootScope.reload_search = false;
    $rootScope.http_worker = null;
    toggle_sponsor_state = getCookie("sponsors");

    if(toggle_sponsor_state === 0 || toggle_sponsor_state === "0")
    {
      jQuery('.sponsor-hide-welcome, .sponsor-hide-newsletter, .sponsor-hide-contact').slideUp();
    }
    else
    {
      jQuery(".welcome-link").addClass("sponsor-link-active");
    }

    if(getCookie("location_id") != "")
    {
      jQuery('#contact-footer-link').show();
      jQuery('#contact-header-link').show();
    }
    else
    {
      jQuery('#contact-footer-link').hide();
      jQuery('#contact-header-link').hide();
    }

    functions.nospin = function()
    {
      if(spinner != null)
      {
        // Disable the most recently used spinner.
        spinner.removeClass("fa fa-cog fa-spin").addClass("fa-check-circle");

        setTimeout(function() {
          spinner.removeClass("fa-check-circle").addClass(oldclass);
          spinner = null;
        }, 1000);
      }
    };

    functions.spin = function(object)
    {
      functions.nospin();   // Make sure any other spinners are stopped.
      spinner   = object; // Save the spinner for later.

      try
      {
        oldclass  = spinner.attr("class").replace("fa ", "");
        spinner.removeClass(oldclass).addClass("fa fa-cog fa-spin");
      }
      catch(e)
      {
        spinner = null;
      }
    };

    functions.wait = function(action)
    {
      wait(action, "This dialog will close automatically when complete.");
    };

    functions.done = function()
    {
      swal.close();
    };

    functions.loading = function()
    {
      // wait("Loading...", "This dialog will close automatically when complete.");
    // };

    // functions.loadingdiv = function()
    // {
      jQuery(".loading").css({opacity: 100});
    };

    functions.loaded = function()
    {
      // swal.close();
      jQuery(".loading").css({opacity: 0});
    };

    functions.save = function(form)
    {
      form.$setPristine();
    };

    functions.appendToFormData = function(formData, obj)
    {
      // Manually serialize all our object properties into the form data.
      for(let k in obj)
      {
            if(obj.hasOwnProperty(k))
            {
              // Everything appended is converted to a string, so null becomes "null" and is saved as such.
              // Simply not passing the value will let PHP treat nulls properly.
              if(obj[k] != null)
              {
                if(typeof obj[k] == "object")
                {
                  formData.append(k, angular.toJson(obj[k]));
                }
                else
                {
                  formData.append(k, obj[k]);
                }
              }
            }
        }

        return formData;
    };

    functions.yesNo = function(input)
    {
        return input ? 'Yes' : 'No';
    };

    functions.nicedate = function(data)
    {
      if(data == null || data == "0000-00-00 00:00:00" || data == "0000-00-00")
      {
        return "";
      }

      data = data.replace(" ", "T");
      return $filter('date')(data, "medium"); //, comparator);
    };

    functions.email = function(data)
    {
      if(data)
      {
        return '<a href="mailto:' + data + '">' + data + '</a>';
      }
      else
      {
        return "";
      }
    };

    functions.website = function(data)
    {
      if(data)
      {
        return '<a href="' + data + '" target="_blank">' + data + '</a>';
      }
      else
      {
        return "";
      }
    };

    functions.na = function()
    {
      info("TODO", "This needs to be implemented.", true, null);
    };

    functions.geocode = function(latitude, longitude, callback)
    {
      if(latitude == "" || longitude == "")
      {
        if(typeof callback == "function")
        {
          callback();
        }
        return;
      }

      //let key = "AIzaSyBo5txW499y1yBIV32VwNkZ3q7ShkGonFU";
      let old_location      = $rootScope.location;
      let old_location_display  = $rootScope.location_display;
      let old_postalcode      = $rootScope.postalcode;
      let old_latitude      = $rootScope.latitude;
      let old_longitude       = $rootScope.longitude;

      // $rootScope.location    = "";
      // $rootScope.new_location  = $rootScope.location;
      // $rootScope.location_display = "";
      // $rootScope.postalcode    = "";
      // $rootScope.latitude    = "";
      // $rootScope.longitude     = "";
      // $rootScope.$apply();
      // location_parts   = location.split(",");
      // latitude     = location_parts[0];
      // longitude    = location_parts[1];

      $http(
        {
          method: 'POST',
            url: '/ajaxdata/geocode',
            data: jQuery.param({postalcode: old_postalcode, latitude: latitude, longitude: longitude}),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).
      success(function(data, status, headers, config)
      {
        if(typeof data == "object")
        {
          let city        = data["city_name"];
          let region        = data["region_name"];
          let province      = data["province_name"];
          let postalcode      = data["postalcode"];
          let country       = data["country_name"];
          let formatted_address   = (city ? city : region) + ", " + province;
          latitude      = (latitude ? latitude : data["latitude"]);
          longitude       = (longitude ? longitude : data["longitude"]);

          $rootScope.location       = formatted_address;
          $rootScope.new_location     = $rootScope.location;
          $rootScope.location_display   = (city ? city + ", " : (region ? region + ", " : "")) + (data["province_code"] ? data["province_code"] + ", " : "") + (postalcode ? postalcode : "");
          $rootScope.postalcode       = postalcode;
          $rootScope.latitude       = latitude;
          $rootScope.longitude      = longitude;
          $rootScope.city         = (city ? city : (region ? region : ""));
          $rootScope.province       = province;
          $rootScope.province_code    = data["province_code"];
          $rootScope.unit         = (country == "Canada" ? "metric" : "imperial");
        }
        else
        {
          error("Location Not Found", "No location was found matching your request.\n\nYour previous location (if any) will be used.", true, function()
          {
            $rootScope.location     = old_location;
            $rootScope.new_location   = old_location;
            $rootScope.location_display = old_location_display;
            $rootScope.postalcode     = old_postalcode;
            $rootScope.latitude     = old_latitude;
            $rootScope.longitude    = old_longitude;
            $rootScope.$apply();
          });
        }

        functions.nospin();
        if(typeof callback == "function")
        {
          callback();
        }
      }).
      error(function(data, status, headers, config)
      {
        error("Location Not Found", "No location was found matching your request.\n\nYour previous location (if any) will be used.", true, function()
        {
          $rootScope.location     = old_location;
          $rootScope.new_location   = old_location;
          $rootScope.location_display = old_location_display;
          $rootScope.postalcode     = old_postalcode;
          $rootScope.latitude     = old_latitude;
          $rootScope.longitude    = old_longitude;
          $rootScope.$apply();
        });
        functions.nospin();
        if(typeof callback == "function")
        {
          callback();
        }
      });
    };

    functions.getlocation = function(user_triggered, callback, event)
    {
      if(navigator.geolocation)
      {
            navigator.geolocation.getCurrentPosition(function(position)
        {
          if(position.coords.latitude == 0 && position.coords.longitude == 0)
          {
            error("Location Not Available", "Please choose your location manually using the map.", true, callback);
            $rootScope.resetLocation();
          }
          else
          {
            $rootScope.latitude = position.coords.latitude;
            $rootScope.longitude = position.coords.longitude;
            $rootScope.$digest();

            functions.geocode($rootScope.latitude, $rootScope.longitude, function()
            {
              if(typeof callback == "function")
                {
                  callback();
                }
            });
            // $rootScope.check_location(callback);
          }
        },
        function()
        {
            // geolocation not available or declined by user
          if(user_triggered)
            {
              error("Location Declined", "Access to your location is disabled or was declined.\n\nPlease choose your location manually using the map.", true, callback);
              $rootScope.resetLocation();
            }
            else
            {
              if(typeof callback == "function")
              {
                callback();
              }
            }
        });
        }
        else
        {
          // geolocation not supported by browser
          if(user_triggered)
          {
            error("Location Not Available", "Geolocation is not supported by your browser.\n\nPlease choose your location manually using the map.", true, callback);
            $rootScope.resetLocation();
          }
          else
          {
            if(typeof callback == "function")
            {
              callback();
            }
          }
        }
    };

    functions.setguideoptions = function()
    {
      let str = "";

      if($rootScope.search_type == "provides")
      {
        str += "Provides";
      }
      else if($rootScope.search_type == "accepts")
      {
        str += "Accepts";
      }

      if($rootScope.scale_min > 0 && $rootScope.scale_max < 9999)
      {
        str += " " + $rootScope.scale_min + " to " + $rootScope.scale_max + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_max > 1 ? "s" : "");
      }
      else if($rootScope.scale_min == 0 && $rootScope.scale_max < 9999)
      {
        str += " up to " + $rootScope.scale_max + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_max > 1 ? "s" : "");
      }
      else if($rootScope.scale_min > 0 && $rootScope.scale_max == 9999)
      {
        str += " more than " + $rootScope.scale_min + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_min > 1 ? "s" : "");
      }

      if($rootScope.transportation == 0)
      {
        // str += ($rootScope.scale ? ", " : "") + "Pick Up";
      }
      else if($rootScope.transportation > 0)
      {
        str += ($rootScope.scale ? ", " : "") + "Transport " + $rootScope.transportation + " " + ($rootScope.unit == "metric" ? "km" : "miles");
      }

      if($rootScope.pickup == 0)
      {
        // str += ($rootScope.scale ? ", " : "") + "Pick Up";
      }
      else if($rootScope.pickup > 0)
      {
        str += ($rootScope.scale ? ", " : "") + "Pick Up " + $rootScope.pickup + " " + ($rootScope.unit == "metric" ? "km" : "miles");
      }

      $rootScope.guideoptions = str;

      try
      {
        if($rootScope.topic.topic_is_tag)
          {
            // Clear any existing filters.
            for(let i=0; i<($rootScope.filters.length > 2 ? $rootScope.filters.length : 2); i++)
            {
              if(typeof $rootScope.filters[i] == "undefined" || $rootScope.filters[i] == null)
            {
              $rootScope.filters[i] = {};
              }
              else
            {
              $rootScope.filters[i].value = [];
            }
            }

            // Set the appropriate filter (accepts/provides).
            if($rootScope.search_type == "provides")
          {
            $rootScope.filters[1].value.push($rootScope.topic.topic_tags[0]);
          }
          if($rootScope.search_type == "accepts")
          {
            $rootScope.filters[0].value.push($rootScope.topic.topic_tags[0]);
          }

          // Max out other filters to make them irrelevant.
          $rootScope.radius_index   = $rootScope.distances.length-1;
          $rootScope.scale_min    = 0;
          $rootScope.scale_max    = 9999;
          $rootScope.transportation   = 0;
          $rootScope.pickup       = 0;
        }
      }
      catch(e)
      {

      }
    };

    functions.setscaleoptions = function()
    {
      if($rootScope.scale_min > 0 && $rootScope.scale_max < 9999)
      {
        $rootScope.scale = $rootScope.scale_min + " to " + $rootScope.scale_max + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_max > 1 ? "s" : "");
      }
      else if($rootScope.scale_min == 0 && $rootScope.scale_max < 9999)
      {
        $rootScope.scale = "Up to " + $rootScope.scale_max + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_max > 1 ? "s" : "");
      }
      else if($rootScope.scale_min > 0 && $rootScope.scale_max == 9999)
      {
        $rootScope.scale = "More than " + $rootScope.scale_min + " " + ($rootScope.unit == "metric" ? "tonne" : "ton") + ($rootScope.scale_min > 1 ? "s" : "");
      }
      else if($rootScope.scale_min == 0 && $rootScope.scale_max == 9999)
      {
        $rootScope.scale = "";
      }

      if($rootScope.transportation == 0)
      {
        // $rootScope.scale += ($rootScope.scale ? ", " : "") + "Pick Up";
      }
      else if($rootScope.transportation > 0)
      {
        $rootScope.scale += ($rootScope.scale ? ", " : "") + "Transport " + $rootScope.transportation + " " + ($rootScope.unit == "metric" ? "km" : "miles");
      }

      if($rootScope.pickup == 0)
      {
        // $rootScope.scale += ($rootScope.scale ? ", " : "") + "Pick Up";
      }
      else if($rootScope.pickup > 0)
      {
        $rootScope.scale += ($rootScope.scale ? ", " : "") + "Pick Up " + $rootScope.pickup + " " + ($rootScope.unit == "metric" ? "km" : "miles");
      }
    };

    functions.setfilters = function()
    {
      for(let i=0; i<$rootScope.filters.length; i++)
      {
        if(typeof $rootScope.filters[i] == "undefined" || $rootScope.filters[i] == null)
        {
          $rootScope.filters[i] = {label: "", value: []};
        }

        if(typeof $rootScope.filters[i].value == "undefined" || $rootScope.filters[i].value == null)
        {
          $rootScope.filters[i].value = [];
        }

        $rootScope.filters[i].label = "";

        try
        {
          var count = $rootScope.filters[i].value.length;

          if(count > 0)
          {
            $rootScope.filters[i].label = count + (count == 1 ? " item" : " items") + " selected";
          }
        }
        catch(e)
        {
          // $rootScope.filters[i].value = [];
        }
      }

      // if($rootScope.search_type == "accepts")
      // {

      // }

    };

    functions.count_watchers = function()
    {
      var root = angular.element(document.getElementsByTagName('body'));

        var watchers = [];

        var f = function (element) {
            angular.forEach(['$scope', '$isolateScope'], function (scopeProperty) {
                if (element.data() && element.data().hasOwnProperty(scopeProperty)) {
                    angular.forEach(element.data()[scopeProperty].$$watchers, function (watcher) {
                        watchers.push(watcher);
                    });
                }
            });

            angular.forEach(element.children(), function (childElement) {
                f(angular.element(childElement));
            });
        };

        f(root);

        // Remove duplicate watchers
        var watchersWithoutDuplicates = [];
        angular.forEach(watchers, function(item) {
            if(watchersWithoutDuplicates.indexOf(item) < 0) {
                 watchersWithoutDuplicates.push(item);
            }
        });

        // console.log(watchersWithoutDuplicates.length + " active $$watchers");
    };

    // setInterval(function() { functions.count_watchers() }, 5000);

    $rootScope.get_topic_background = function(tile, topic)
    {
      try
      {
        return '/images/thumbnails/topics/' + topic.picture_file.replace(/\.(jpg|png|gif)/i, '_260x260.$1', 'gi');
      }
      catch(e)
      {
        return null;
      }
    };

    $rootScope.setSector = function(id, event)
    {
      if(id == "")
      {
        id = null;
      }

      if(typeof $rootScope.filters[3] == "undefined")
      {
        $rootScope.filters[3] = {tag_id: 4, value: []};
      }

      if(id == null)
      {
        $rootScope.filters[3].value = [];
      }
      else
      {
        $rootScope.filters[3].value = [{"id":id}];
      }

      jQuery(".sector-button").removeClass("pure-button-primary");
      try
      {
        jQuery(event.target).addClass("pure-button-primary");
      }
      catch(e){}
      $rootScope.reload_search = true;
    };

    $rootScope.back = function()
    {
      history.go(-1);
    };

    $rootScope.closeStatus = function()
    {
      jQuery("#overlay").animate(
      {
        opacity: "toggle"
      }, 300, function()
      {
        jQuery("#status-dialog").appendTo("body").addClass("hidden");
      });
    };

    $rootScope.closeLocation = function()
    {
      jQuery('#guide-navigation').addClass('hidden');
      jQuery("#guide-overlay").fadeOut(500, function()
      {
        jQuery("#location-dialog").appendTo("#guide-navigation").addClass("hidden");
        jQuery(".overlaybg").css({display: 'none'});
      });
    };

    $rootScope.closeTopics = function()
    {
      jQuery("#guide-overlay").fadeOut(500, function()
      {
        jQuery("#topics-dialog").appendTo("#guide-navigation").addClass("hidden");
        jQuery(".overlaybg").css({display: 'none'});
      });
    };

    $rootScope.check_location = function(callback)
    {
          // if($rootScope.postalcode == null || $rootScope.postalcode == "" ||
          //    $rootScope.location_display == null || $rootScope.location_display == "")
          // {
          //  return;
          // }

          jQuery("#status-heading").html("Finding Your Community");
          jQuery("#status-content").html("<p>Each community has it's own Recyclopedia section.</p>" +
                                         "<p class='faded'>This dialog will close automatically.</p>");
          jQuery("#status-buttons").html("<em class='fa fa-spin fa-cog fa-3x'></em>");

          jQuery("#status-dialog").appendTo("#overlay").removeClass("hidden");
      jQuery("#overlay").css(
            {
              left: "auto",
              right: 0
            }).animate(
      {
        opacity: "toggle"
      }, 300);

      // info("Loading...", "Looking for the closest Recyclopedia section.\n\nThis dialog will close automatically.", false, null);
      if($rootScope.latitude == "" || $rootScope.longitude == "")
      {
        return;
      }

      functions.geocode($rootScope.latitude, $rootScope.longitude, function()
      {
        // Check if the user is in the right region
            $http(
          {
            method: 'POST',
              url: '/ajaxdata/check_location_by_postalcode',
              data: jQuery.param({postalcode: $rootScope.postalcode}), //, location: $rootScope.location_display}),
              headers: {'Content-Type': 'application/x-www-form-urlencoded'}
          }).
        success(function(data, status, headers, config)
        {
          if(typeof data == "object")
          {
            if(data["request"] != null && data["response"] != null)
            {
              // TODO: Further optimize conditional logic below
              // If the city, region, province/state and country match or if only the province/state and country match, forward to that location
              if(!data["response"]["section_postalcodes"].length &&
                (data["request"]["city_id"] == data["response"]["city_id"] &&
                data["request"]["region_id"] == data["response"]["region_id"] &&
                data["request"]["province_id"] == data["response"]["province_id"] &&
                data["request"]["country_id"] == data["response"]["country_id"]) ||

                (data["request"]["region_id"] == data["response"]["region_id"] &&
                data["request"]["country_id"] == data["response"]["country_id"] &&
                !data["response"]["city_id"]) ||

                (data["request"]["province_id"] == data["response"]["province_id"] &&
                data["request"]["country_id"] == data["response"]["country_id"] &&
                !data["response"]["city_id"] && !data["response"]["region_id"])
              )
              {
                // This will either be a city or region.
                // City or region or province should match
                // Country must match
                setCookie("recyclopedia_city", (data["response"]["city_name"] ? data["response"]["city_name"] : data["response"]["region_name"]), 365);
                setCookie("recyclopedia_province", data["response"]["province_name"], 365);
                setCookie("location_id", data["response"]["location_id"], 365);
                window.location = data["response"]["url"];
              }
              // else if((data["response"]["city_id"] == null || data["response"]["city_id"] == '') &&
              //         data["response"]["province_id"] && data["request"]["province_id"] == data["response"]["province_id"] && data["request"]["country_id"] == data["response"]["country_id"])
              // {
              //  // This will be a province/state wide area.
              //  // Province must match
              //  // Country must match
              //  setCookie("recyclopedia_city", data["response"]["province_name"], 365);
              //  setCookie("recyclopedia_province", data["response"]["country_name"], 365);
              //  setCookie("location_id", data["response"]["location_id"], 365);
              //  window.location = data["response"]["url"];
              // }
              else
              {
                // Bad news
                jQuery("#status-heading").html("Ooops!");
                jQuery("#status-buttons").html("");
                  jQuery("#status-content").html("<p>We couldn't find a Recyclopedia Community for the location:</p><p>" + data["request"]["city_name"] + ", " + data["request"]["province_name"] + ", " + data["request"]["postalcode"] + "</p>" +
                                                   "<p class='faded'>(Recyclopedia " + data["request"]["city_name"] + " has not yet been set up.)<p>" +
                                                   "<div class='padded'>" +
                                        " <h3 class='faded'>Closest Recyclopedia</h3>" +
                                        " <p class='faded' style='text-align: center;'>The closest Recyclopedia section is in " + (data["response"]["city_name"] || data["response"]["region_name"] || data["response"]["province_name"]) + ".</p>" +
                                        " <p class='faded' style='text-align: center;'>Would you like to go to " + (data["response"]["city_name"] || data["response"]["region_name"] || data["response"]["province_name"]) +  " or choose a new location?</p></div>" +
                                        " <button type='button' class='pure-button' onclick=\"setCookie('location_id', " + data["response"]["location_id"] + ", 365); window.location = '" + data["response"]["url"] + "'\"><em class='fa fa-fw fa-recycle'></em> " + (data["response"]["city_name"] || data["response"]["region_name"] || data["response"]["province_name"]) + "</button>" +
                                " <button type='button' class='pure-button' onclick=\"gotolist(event);\"><em class='fa fa-fw fa-map-marker'></em> Set Location</button>" +
                                " <div class='pure-hidden-sm pure-hidden-xs'>" +
                                        "   <h3 class='faded'>Get Set Up</h3>" +
                                        "   <p class='faded' style='text-align: center;'><a href='/about'>Find out how</a> to set up a Recyclopedia section for " + data["request"]["city_name"] + ".</p>" +
                                        " </div>" +
                                        "</div>");

                  // jQuery("#status-container").html(compiled[0]);
                // nerivon_confirm("Closest Recyclopedia Section",
                //                 "Recyclopedia section for " + data["request"]["city_id"] + " has not yet been set up.\n\n" +
                //                 "Are you the Recycling Coordinator for " + data["request"]["city_id"] + "?\nContact us to find out how to claim your Recyclopedia section.\n\n\n" +
                //                 "The closest Recyclopedia section is in " + data["response"]["city_id"] + ", " +
                //                 data["response"]["province_id"] + ".\n\nWould you like to go there?", "error", true, function()
            //             {
            //              window.location = data["response"]["url"];
            //             });
                    }
                }
                else
            {
              $rootScope.showerror();
            }
          }
          else
          {
            $rootScope.showerror();
            // error("Error", "Unable to determine the closest Recyclopedia section.\n\nDid you drop the pin in a forest?", true, null);
          }
          if(typeof callback == "function")
          {
            callback();
          }
        }).
        error(function(data, status, headers, config)
        {
          $rootScope.showerror();
          // error("Error", "Unable to determine the closest Recyclopedia section.\n\nDid you drop the pin in a forest?", true, null);
          functions.nospin();
          if(typeof callback == "function")
          {
            callback();
          }
        });
      });
    };

    $rootScope.showerror = function()
    {
      jQuery("#status-heading").html("Ooops!");
      jQuery("#status-content").html("<p>Sorry, but we were unable to match your location with a community. Please choose a new location or select from the list on the page.</p>");
          jQuery("#status-buttons").html("<button type='button' class='pure-button' onclick=\"gotolist(event);\">OK</button>");

          $rootScope.resetLocation();
    };

    $rootScope.resetLocation = function()
    {
      setCookie("latitude",       0, -1);
      setCookie("longitude",      0, -1);
      setCookie("city",         0, -1);
      setCookie("location",       0, -1);
      setCookie("location_display",   0, -1);
      setCookie("province",       0, -1);
      setCookie("province_code",    0, -1);
      setCookie("postalcode",     0, -1);

      $rootScope.latitude   = 43.642814;
      $rootScope.longitude  = -79.38722;
    };

    $rootScope.gotoCommunity = function(url)
    {
      setCookie("latitude",       0, -1);
      setCookie("longitude",      0, -1);
      setCookie("city",         0, -1);
      setCookie("location",       0, -1);
      setCookie("location_display",   0, -1);
      setCookie("province",       0, -1);
      setCookie("province_code",    0, -1);
      setCookie("postalcode",     0, -1);

      window.location = url;
    };

    $rootScope.hide_sponsors = function(event)
    {
      event.preventDefault();

      toggle_sponsor_state = 0;
      jQuery('.sponsor-hide-welcome, .sponsor-hide-newsletter, .sponsor-hide-contact').slideUp();
      jQuery('.welcome-link, .contact-link, .newsletter-link').removeClass('sponsor-link-active');
      setCookie("sponsors", toggle_sponsor_state, 365);
    };

    $rootScope.show_welcome = function(event)
    {
      event.preventDefault();

      // If welcome tab is already open, hide it.
      if(jQuery('.welcome-link').hasClass('sponsor-link-active'))
      {
       $rootScope.hide_sponsors(event);
       return;
      }

      jQuery('.newsletter-link').removeClass('sponsor-link-active');
      jQuery('.contact-link').removeClass('sponsor-link-active');
      jQuery('.welcome-link').addClass('sponsor-link-active');

      toggle_sponsor_state = 1;

      jQuery('.sponsor-hide-contact, .sponsor-hide-newsletter').slideUp(250, function()
      {
        jQuery('.sponsor-hide-welcome').slideDown(250);
      });

      setCookie("sponsors", toggle_sponsor_state, 365);
    };

    $rootScope.show_newsletter = function(event)
    {
      event.preventDefault();

      // If contact tab is already open, hide it.
      if(jQuery('.newsletter-link').hasClass('sponsor-link-active'))
      {
       $rootScope.hide_sponsors(event);
       return;
      }

      jQuery('.newsletter-link').addClass('sponsor-link-active');
      jQuery('.contact-link').removeClass('sponsor-link-active');
      jQuery('.welcome-link').removeClass('sponsor-link-active');

      toggle_sponsor_state = 1;

      jQuery('.sponsor-hide-welcome, .sponsor-hide-contact').slideUp(250, function()
      {
        jQuery('.sponsor-hide-newsletter').slideDown(250);
      });

      setCookie("sponsors", toggle_sponsor_state, 365);
    };

    $rootScope.show_contact = function(event)
    {
      event.preventDefault();

      // If contact tab is already open, hide it.
      if(jQuery('.contact-link').hasClass('sponsor-link-active'))
      {
       $rootScope.hide_sponsors(event);
       return;
      }

      jQuery('.contact-link').addClass('sponsor-link-active');
      jQuery('.welcome-link').removeClass('sponsor-link-active');
      jQuery('.newsletter-link').removeClass('sponsor-link-active');

      toggle_sponsor_state = 1;

      jQuery('.sponsor-hide-welcome, .sponsor-hide-newsletter').slideUp(250, function()
      {
        jQuery('.sponsor-hide-contact').slideDown(250);
      });

      setCookie("sponsors", toggle_sponsor_state, 365);
    };

    $rootScope.scroll_to_contact = function()
    {
      window.scrollTo(0, 0);
      setTimeout(function()
      {
        jQuery('.contact-link').parent().addClass('sponsor-link-active');
        jQuery('.welcome-link').parent().removeClass('sponsor-link-active');
        jQuery('.newsletter-link').parent().removeClass('sponsor-link-active');
        toggle_sponsor_state = 1;
        jQuery('.sponsor-hide-welcome, .sponsor-hide-newsletter').slideUp(250, function()
        {
          jQuery('.sponsor-hide-contact').slideDown(250);
        });
        setCookie("sponsors", toggle_sponsor_state, 365);
      }, 300);
    };

    $rootScope.contact_sponsor_form = function()
    {
      jQuery( "#contact-sponsor-dialog" ).dialog(
      {
        width: 1000,
        maxWidth: jQuery(window).width() * 0.9,
        maxHeight: jQuery(window).height() * 0.9,
        modal: true,
        show:
        {
              effect: "fade",
              duration: 300
            },
            hide:
            {
              effect: "fade",
              duration: 300
            }
      });
    };

    $rootScope.contact_sponsor_send = function(e)
    {
      functions.wait("Sending...");

      $rootScope.contact_sponsor.location_id = location_id;

      $http(
        {
          method: 'POST',
            url: '/main/contact_sponsor',
            data: jQuery.param(angular.copy($rootScope.contact_sponsor)),
            headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }).
      success(function(data, status, headers, config)
      {
        success("Message Sent", "Your message was sent.", true, function()
            {
          jQuery('#contact-sponsor-name, #contact-sponsor-email, #contact-sponsor-phone, #contact-sponsor-message').val('');
            });
      }).
      error(function(data, status, headers, config)
      {
        error("Error Sending Message", data, true, null);
      });
    };

    $rootScope.contact_sponsor_close = function()
    {
      jQuery("#contact-sponsor-dialog").dialog("close");
    };

    jQuery(".close-button").click(function(e)
    {
      e.preventDefault();

      var close = jQuery(this); // Save reference for the confirm function.

      if(jQuery(this).closest("form").hasClass("ng-dirty"))
      {
        nerivon_confirm("Unsaved Changes", "You have unsaved changes. Close anyway?", "warning", true, function()
                        {
                          window.location = close.attr("href");
                        });
      }
      else
      {
        window.location = jQuery(this).attr("href");
      }
    });

    // Get values from cookies.
    $rootScope.location         = decodeURIComponent(getCookie("location")).replace(/\+/g, " ");
    $rootScope.city           = decodeURIComponent(getCookie("city")).replace(/\+/g, " ");
    $rootScope.province         = decodeURIComponent(getCookie("province")).replace(/\+/g, " ");
    $rootScope.province_code      = decodeURIComponent(getCookie("province_code")).replace(/\+/g, " ");
    $rootScope.new_location       = $rootScope.location;
    $rootScope.location_display     = decodeURIComponent(getCookie("location_display")).replace(/\+/g, " ");
    $rootScope.address          = decodeURIComponent(getCookie("address")).replace(/\+/g, " ");
    $rootScope.postalcode         = decodeURIComponent(getCookie("postalcode")).replace(/\+/g, " ");
    $rootScope.radius_index       = decodeURIComponent(getCookie("radius")).replace(/\+/g, " ");
    $rootScope.latitude         = decodeURIComponent(getCookie("latitude")).replace(/\+/g, " ");
    $rootScope.longitude        = decodeURIComponent(getCookie("longitude")).replace(/\+/g, " ");
    $rootScope.keywords         = decodeURIComponent(getCookie("keywords")).replace(/\+/g, " ");
    $rootScope.search_type        = decodeURIComponent(getCookie("search_type")).replace(/\+/g, " ");
    // $rootScope.scale_guide       = Number(getCookie("scale_guide"));
    // $rootScope.scale_guide_unit    = decodeURIComponent(getCookie("scale_guide_unit")).replace(/\+/g, " ");
    $rootScope.unit           = decodeURIComponent(getCookie("unit")).replace(/\+/g, " ");
    $rootScope.scale_min        = Number(getCookie("scale_min"));
    $rootScope.scale_max        = Number(getCookie("scale_max"));
    $rootScope.transportation       = Number(getCookie("transportation"));
    $rootScope.pickup           = Number(getCookie("pickup"));
    $rootScope.guideoptions       = "";
    $rootScope.filters          = [];
    $rootScope.topic_id         = Number(getCookie("topic_id"));
    $rootScope.section          = decodeURIComponent(getCookie("section")).replace(/\+/g, " ");
    $rootScope.recyclopedia_city    = decodeURIComponent(getCookie("recyclopedia_city")).replace(/\+/g, " ");
    $rootScope.recyclopedia_province  = decodeURIComponent(getCookie("recyclopedia_province")).replace(/\+/g, " ");

    if(getCookie("filters") != "")
    {
      $rootScope.filters = JSON.parse(getCookie("filters"));
    }

    if(!$rootScope.radius_index)
    {
      $rootScope.radius_index = 7;
      $rootScope.radius     = $rootScope.distances[$rootScope.radius_index];
    }
    if(!$rootScope.search_type)
    {
      $rootScope.search_type = "accepts";
    }
    if(!$rootScope.scale_guide)
    {
      $rootScope.scale_guide = 1000;
    }
    if(!$rootScope.scale_guide_unit)
    {
      $rootScope.scale_guide_unit = "km";
    }
    if(!$rootScope.unit)
    {
      $rootScope.unit = "metric";
    }
    if(!$rootScope.scale_min)
    {
      $rootScope.scale_min = 0;
    }
    if(!$rootScope.scale_max)
    {
      $rootScope.scale_max = 9999;
    }
    if(!$rootScope.transportation)
    {
      $rootScope.transportation = 0;
    }
    if(!$rootScope.pickup)
    {
      $rootScope.pickup = 0;
    }

    // Now that we've got the values, add a watch to update the cookies automatically.
    $rootScope.$watch
    (
      function(scope){ return $rootScope.location; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("location", $rootScope.location, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.location_display; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("location_display", $rootScope.location_display, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.address; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("address", $rootScope.address, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.city; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("city", $rootScope.city, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.province; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("province", $rootScope.province, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.province_code; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("province_code", $rootScope.province_code, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.postalcode; },
            function(newValue, oldValue)
            {
              if(newValue == oldValue) { return; }

              setCookie("postalcode", $rootScope.postalcode, 365);
              $rootScope.reload_search = true;
            }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.radius_index; },
            function(newValue, oldValue)
            {
              if(newValue == oldValue) { return; }

              $rootScope.radius = $rootScope.distances[$rootScope.radius_index];
              setCookie("radius", $rootScope.radius_index, 365);
              $rootScope.reload_search = true;
            }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.latitude; },
            function(newValue, oldValue)
            {
              if(newValue == oldValue) { return; }

              setCookie("latitude", $rootScope.latitude, 365);
              $rootScope.reload_search = true;
            }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.longitude; },
            function(newValue, oldValue)
            {
              if(newValue == oldValue) { return; }

              setCookie("longitude", $rootScope.longitude, 365);
              $rootScope.reload_search = true;
            }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.keywords; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("keywords", $rootScope.keywords, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.search_type; },
            function(newValue, oldValue)
            {
              if(newValue == oldValue) { return; }

              functions.setguideoptions();
              setCookie("search_type", $rootScope.search_type, 365);
              $rootScope.reload_search = true;
            }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.unit; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } setCookie("unit", $rootScope.unit, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.scale_min; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } functions.setscaleoptions(); functions.setguideoptions(); setCookie("scale_min", $rootScope.scale_min, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.scale_max; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } functions.setscaleoptions(); functions.setguideoptions(); setCookie("scale_max", $rootScope.scale_max, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.transportation; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } functions.setscaleoptions(); functions.setguideoptions(); setCookie("transportation", $rootScope.transportation, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.pickup; },
            function(newValue, oldValue) { if(newValue == oldValue) { return; } functions.setscaleoptions(); functions.setguideoptions(); setCookie("pickup", $rootScope.pickup, 365); }
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.topic; },
            function(newValue, oldValue)
            {
              if(newValue.topic_id == oldValue.topic_id || newValue.topic_id == null)
              {
                return;
              }

              functions.setguideoptions();
              setCookie("topic_id", $rootScope.topic_id, 365);
              $rootScope.reload_search = true;
            },
            true
        );

        $rootScope.$watch
    (
      function(scope){ return $rootScope.filters; },
            function(newValue, oldValue)
            {
              functions.setfilters();
              setCookie("filters", JSON.stringify($rootScope.filters), 365);
            },
            true
        );

    functions.setguideoptions();
    functions.setscaleoptions();

    // No location is set yet, use the CN Tower.
        if($rootScope.latitude == "" || $rootScope.latitude == null || $rootScope.longitude == "" || $rootScope.longitude == null)
    {
      $rootScope.latitude = 43.6417941;
      $rootScope.longitude = -79.3865126;
    }

        // Now that we have watchers in place, load the topic if there is one.
    try
    {
      if($rootScope.topic_id > 0)
      {
        $http.get('/ajaxdata/get/topic/' + $rootScope.topic_id).
        success(function(data, status, headers, config)
        {
          $rootScope.topic = data;
          setCookie("topic_id", $rootScope.topic.topic_id, 365);

            if($rootScope.topic.topic_is_tag)
            {
              for(let i=0; i<($rootScope.filters.length > 2 ? $rootScope.filters.length : 2); i++)
              {
                $rootScope.filters[i] = {value: []};
              }

              if($rootScope.search_type == "provides")
            {
              $rootScope.filters[1].value = $rootScope.topic.topic_tags[0];
            }
            if($rootScope.search_type == "accepts")
            {
              $rootScope.filters[0].value = $rootScope.topic.topic_tags[0];
            }
            $rootScope.radius_index   = $rootScope.distances.length-1;
            $rootScope.scale_min    = 0;
            $rootScope.scale_max    = 9999;
            $rootScope.transportation   = 0;
          }
        }).
        error(function(data, status, headers, config)
        {
          // error("Error Loading Topic", data, true, null);
        });
      }
    }
    catch(e){}

    // if(navigator.geolocation && ($rootScope.latitude == "" || $rootScope.latitude == null ||
    //                   $rootScope.longitude == "" || $rootScope.longitude == null))
    // {
    //  var url = window.location.href;
    //  url = url.substring(url.indexOf("/")+1);
    //  url = url.substring(url.indexOf("/")+1);
    //  url = url.substring(url.indexOf("/")+1);

    //  if(url.indexOf("#") !== -1)
    //  {
    //    url = url.substring(0, url.indexOf("#"));
    //  }

    //  if(url == "")
    //  {
    //    functions.getlocation(false, null);
    //  }
   //    }

      jQuery(document).ready(function()
      {
        jQuery.nvTabs();
        jQuery.nvTabsSelect();
      });
  }]);

  app.value("functions", functions);

  app.filter('yesNo', function()
  {
      return function(input)
      {
          return input ? 'Yes' : 'No';
      };
  });

  app.filter('htmlToPlaintext', function()
  {
      return function(text)
      {
        return String(text).replace(/<[^>]+>/gm, '');
      };
    });

  app.filter('htmlPreview', function()
  {
      return function(text)
      {
        if(text)
        {
          return String(text).replace(/<[^>]+>/gm, '').substring(0, 100) + "...";
        }
        else
        {
          return "";
        }
      };
    });

    app.filter('distance', function()
  {
      return function(input)
      {
          return input == "metric" ? 'km' : 'miles';
      };
  });

    app.filter('distance_opposite', function()
  {
      return function(input)
      {
          return input == "metric" ? 'miles' : 'km';
      };
  });

    app.filter('ton', function()
  {
      return function(input)
      {
          return input == "metric" ? 'tonne' : 'ton';
      };
  });

    app.filter('tons', function()
  {
      return function(input)
      {
          return input == "metric" ? 'tonnes' : 'tons';
      };
  });

  app.filter('escape', function()
  {
      return window.encodeURIComponent;
  });

  // app.directive('loading', function()
  // {
 //     return {
 //       templateUrl: '/directives/loading'
 //     };
  // });

  app.directive('vcardContact', function()
  {
      return {
        templateUrl: '/directives/vcard-contact'
      };
  });

  app.directive('vcardOrganization', function()
  {
      return {
        templateUrl: '/directives/vcard-organization',
        link: function($scope, element, attrs)
        {
          jQuery.nvTabs(".nv-tabs");
        }
      };
  });

  app.directive('vcardOrganizationMap', function()
  {
      return {
        templateUrl: '/directives/vcard-organization-map'
      };
  });

  app.directive('topicTile', function()
  {
      return {
        templateUrl: '/directives/topic-tile'
      };
  });

  app.directive('topicList', function()
  {
      return {
        templateUrl: '/directives/topic-list'
      };
  });

  app.directive('topicAka', function()
  {
      return {
        templateUrl: '/directives/topic-aka'
      };
  });

  app.directive('ckEditor', function()
  {
    return {
      require: '?ngModel',
      link: function(scope, elm, attr, ngModel)
      {
        jQuery(document).ready(function()
        {
          var ck = CKEDITOR.replace(elm[0]);

          if (!ngModel) return;

          ck.on('pasteState', function()
          {
            scope.$apply(function()
            {
                ngModel.$setViewValue(ck.getData());
            });
          });

          ngModel.$render = function(value)
          {
            ck.setData(ngModel.$viewValue);
          };
        });
      }
    };
  });

  app.directive('myRepeatDirective', function()
  {
      return function(scope, element, attrs)
      {
        if(scope.$last)
        {
          setTimeout(function()
          {
            // This is required because the built in token input script fires before ng-repeat is finished.
            jQuery("[ng-repeat] .tokeninput:visible").each(function()
          {
            var ti = jQuery(this);
            var source    = ti.attr("data-source");
            var allow_new   = ti.attr("data-allow_new");
            var options   = ti.attr("data-options");

            options = JSON.parse(options.replace(/'/g, '"'));
            options.onReady = function()
            {
              ti.addClass("ready");

              jQuery("#token-input-" + ti.attr("id")).keyup(function(e)
              {
                if(e.keyCode == 13)
                {
                  e.preventDefault();

                  if(allow_new === true || allow_new == 1)
                  {
                    var val = jQuery(this).val();

                    if(val == "")
                    {
                      return;
                    }
                    ti.tokenInput("add", {id:val, name:val});
                  }
                }
              });

              // Key Press needs to be disabled for enter key at all times.
              jQuery("#token-input-" + ti.attr("id")).keypress(function(e)
              {
                if(e.keyCode == 13)
                {
                  e.preventDefault();
                }
              });
            };

            ti.tokenInput(source, options);
          });
          }, 10);
        }
      };
  });
})();
